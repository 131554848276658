@import "../../../../styles/variables";

.main-status-bar {
  padding: 8px;
  background-color: $menu-bg-color;
  color: white;
  display: flex;
  justify-content: space-between;

  .secondary {
    display: flex;
    gap: 8px;

    .darken-white {
      color: darken(white, 20%);
    }
  }
}
