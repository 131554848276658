@import "../../../../../../../styles/variables";

.joystick {
  width: 49px;
  height: 49px;
  position: absolute;

  & .indicator {
    background-color: $color-controller-third;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);

    &.pressed {
      background-color: $color-controller-key-pressed-bg;
    }
  }
}