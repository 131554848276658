@import "../../../../../../../styles/variables";

.dpad-key {
  width: 20px;
  height: 20px;
  color: rgba($color-controller-key-text, 0.3);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 0.7rem;

  &.key-pressed {
    color: $color-controller-key-pressed-text;
  }
}
