$color-darkgrey: rgb(22, 22, 22);
$color-darkred: rgb(134, 0, 0);
$color-darkgreen: rgb(0, 134, 0);
$color-blue: #006bff;

$menu-bg-color: $color-darkgrey;
$menu-active-bg-color: lighten($color-darkgrey, 5%);

/* Controller color variables */

$color-controller-primary: rgb(34, 38, 41);
$color-controller-secondary: rgb(43, 48, 51);
$color-controller-third: rgb(16, 16, 16);

$color-controller-key-bg: rgb(0, 0, 0);
$color-controller-key-text: rgb(204, 204, 204);

$color-controller-key-pressed-bg: rgb(0, 0, 0);
$color-controller-key-pressed-text: rgb(43, 109, 6);

$color-controller-yellow: rgb(219, 178, 44);
$color-controller-red: rgb(207, 57, 48);
$color-controller-green: rgb(136, 191, 87);
$color-controller-blue: rgb(0, 156, 211);