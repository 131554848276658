@import "../../../styles/variables";

.main-layout {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 200px auto 1fr;

  .main-content {
    background-color: rgb(216, 216, 216);
    .main-content-wrapper {
      background-color: white;
      max-width: 1024px;
      margin: auto;
      padding: 16px;
      height: 100%;
      overflow-y: hidden;
    }
  }
}
