@import "../../../../styles/variables";

.main-menu {
    background-color: $menu-bg-color;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none;
        color: white;
        padding: 16px 32px;
        transition: all .3s ease;

        &.active {
            background-color: $menu-active-bg-color;
        }

        &:hover {
            background-color: $menu-active-bg-color;
        }
    }
}