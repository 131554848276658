@import "../../../styles/variables";

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgb(0, 0, 0, 0.1);
  margin-bottom: 16px;

  .title {
    font-size: 2rem;
    font-weight: bold;
  }
  .actions {
    display: flex;
    gap: 8px;

    & div {
      padding: 8px;
      border-radius: 5px;
      transition: all 0.2s ease;

      &:hover {
        background-color: darken(white, 10%);
      }
    }
  }
}
