@import "./variables";

.input-field {
  border: none;
  border-bottom: 1px solid $color-darkgrey;
  background-color: darken(white, 5%);
  padding: 12px 8px;
  width: 100%;

  &:active, &:focus {
    outline: none;
  }
}