@import "../../../../../styles/variables";

.controller {
  user-select: none;

  & h1 {
    text-align: center;
  }

  & .controller-wrapper {
    width: 400px;
    height: 300px;
    background-image: url(/images/controller_empty.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
  }
}
