@import "../../../../../../../styles/variables";

.key {
  width: 27px;
  height: 27px;
  background-color: $color-controller-key-bg;
  color: $color-controller-key-text;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &.key-special {
    width: 20px;
    height: 20px;
    border-radius: 4px;

    & i {
      font-size: 0.6rem;
    }
  }

  &.key-pressed {
    background-color: $color-controller-key-pressed-bg;
    color: $color-controller-key-pressed-text;

    &.key-colored-blue {
      color: $color-controller-blue;
    }

    &.key-colored-red {
      color: $color-controller-red;
    }

    &.key-colored-green {
      color: $color-controller-green;
    }

    &.key-colored-yellow {
      color: $color-controller-yellow;
    }
  }
}
