@import "../../../../../../styles/variables";

.connection-indicator {
  display: flex;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;

  .connected {
    color: $color-darkgreen;
  }

  .disconnected {
    color: $color-darkred;
  }
}
