@import "../../../../styles/variables";

.main-header {
  background-image: url(/images/background.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  min-height: 200px;
  

  img {
    max-height: 100px;
    user-select: none;
  }
}
