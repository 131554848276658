@import "./variables";

.btn {
  border: none;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:disabled {
    cursor: inherit;
  }

  &.btn-primary {
    background-color: $color-blue;
    color: white;
    &:hover, &:active {
      &:not([disabled]) {
        background-color: darken($color-blue, 10%);
      }
    }
  }
}